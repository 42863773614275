<template>
  <div>
    <div id="heatMapHtml"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      amapObject: null, // 地图对象
      heatmapObject: null,
      publisherCityCode: this.$store.getters.token.userInfo.publishers[0].cityCode,
      mapCityCenter: [
        { cityCode: '350200', cityName: '厦门市', center: [118.103886046, 24.4892306125] },
        { cityCode: '370200', cityName: '青岛市', center: [120.383414, 36.075069] },
        { cityCode: '110100', cityName: '北京市', center: [116.395645038, 39.9299857781] },
        { cityCode: '330200', cityName: '宁波市', center: [121.579005973, 29.8852589659] },
        { cityCode: '510100', cityName: '成都市', center: [104.076673, 30.550755] },
        { cityCode: '530100', cityName: '昆明市', center: [102.722601, 25.014105] },
        { cityCode: '440600', cityName: '佛山市', center: [113.134025635, 23.0350948405] },
        { cityCode: '350100', cityName: '福州市', center: [119.330221107, 26.0471254966] }
      ],

      flag: 0
    }
  },
  beforeDestroy () {
    this.amapObject && this.amapObject.destroy()
  },
  mounted () {
    // 设置地图宽高
    this.resizeMap()
    const _self = this
    window.addEventListener('resize', function () {
      _self.resizeMap()
    })

    // 延迟加载，防止出现AMap not defined
    setTimeout(() => {
      this.initMapData()
    }, 1000)
  },
  methods: {
    initMapData () {
      this.flag = 0
      // 获取地图中心点
      const center = this.mapCityCenter.find(x => x.cityCode === this.publisherCityCode).center
      this.loadMap(center)
      this.createHeatMap()
    },
    loadMap (center) {
      this.amapObject = new AMap.Map('heatMapHtml', {
        center: center,
        zoom: 12,
        zooms: [10, 16]
      })
    },
    resizeMap () {
      if (document.getElementById('heatMapHtml')) { // 防止切换界面，或者内容丢失后出错
        const centerBlock = document.getElementById('heatMapHtml').parentNode.parentNode.parentNode
        document.getElementById('heatMapHtml').style.width = centerBlock.clientWidth + 'px'
        document.getElementById('heatMapHtml').style.height = centerBlock.clientHeight + 'px'
      }
    },
    createHeatMap () {
      const _self = this
      this.amapObject.plugin(['AMap.HeatMap'], function () {
        // 初始化heatmap对象
        _self.heatmapObject = new AMap.HeatMap(_self.amapObject, {
          radius: 40 // 给定半径
          // gradient: {
          //   0.2: '#00FFFF',
          //   0.4: '#207cca',
          //   0.6: '#31ff00',
          //   0.8: '#f8ff00'
          //   // 0.95: '#ff0500'
          // }
        })
      })
    },
    initHeatMapOrigin (heatData) {
      if (this.amapObject && this.heatmapObject) {
        this.heatmapObject.setDataSet({
          data: heatData.data,
          max: heatData.max
        })
      } else if (this.flag < 10) {
        this.flag++
        setTimeout(() => {
          this.initHeatMapOrigin(heatData)
        }, 1000)
      }
    },
    clearMap () {
      this.amapObject.destroy()
      this.heatmapObject = null
      this.amapObject = null
    }
  }
}
</script>

<style>
.station-heat-type{
  position: absolute;
  top: 50px;
  left: 20px;
  z-index: 9999;
}
</style>
